
// @ts-nocheck


export const localeCodes =  [
  "de-at",
  "de-de",
  "cs-cz",
  "pl-pl",
  "de-ch",
  "fr-ch",
  "it-ch"
]

export const localeLoaders = {
  "de-at": [{ key: "../langs/processed/de-AT.json", load: () => import("../langs/processed/de-AT.json" /* webpackChunkName: "locale__builds_aboutyou_cloud_agency_shop_application_fim_sfa_langs_processed_de_AT_json" */), cache: true }],
  "de-de": [{ key: "../langs/processed/de-DE.json", load: () => import("../langs/processed/de-DE.json" /* webpackChunkName: "locale__builds_aboutyou_cloud_agency_shop_application_fim_sfa_langs_processed_de_DE_json" */), cache: true }],
  "cs-cz": [{ key: "../langs/processed/cs-CZ.json", load: () => import("../langs/processed/cs-CZ.json" /* webpackChunkName: "locale__builds_aboutyou_cloud_agency_shop_application_fim_sfa_langs_processed_cs_CZ_json" */), cache: true }],
  "pl-pl": [{ key: "../langs/processed/pl-PL.json", load: () => import("../langs/processed/pl-PL.json" /* webpackChunkName: "locale__builds_aboutyou_cloud_agency_shop_application_fim_sfa_langs_processed_pl_PL_json" */), cache: true }],
  "de-ch": [{ key: "../langs/processed/de-CH.json", load: () => import("../langs/processed/de-CH.json" /* webpackChunkName: "locale__builds_aboutyou_cloud_agency_shop_application_fim_sfa_langs_processed_de_CH_json" */), cache: true }],
  "fr-ch": [{ key: "../langs/processed/fr-CH.json", load: () => import("../langs/processed/fr-CH.json" /* webpackChunkName: "locale__builds_aboutyou_cloud_agency_shop_application_fim_sfa_langs_processed_fr_CH_json" */), cache: true }],
  "it-ch": [{ key: "../langs/processed/it-CH.json", load: () => import("../langs/processed/it-CH.json" /* webpackChunkName: "locale__builds_aboutyou_cloud_agency_shop_application_fim_sfa_langs_processed_it_CH_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../vue-i18n.config.ts?hash=ebc67d6d&config=1" /* webpackChunkName: "vue_i18n_config_ts_ebc67d6d" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "vue-i18n.config.ts",
  "locales": [
    {
      "iso": "de-AT",
      "domain": "https://www.fielmann.at",
      "code": "de-at",
      "files": [
        "langs/processed/de-AT.json"
      ]
    },
    {
      "iso": "de-DE",
      "domain": "https://www.fielmann.de",
      "code": "de-de",
      "files": [
        "langs/processed/de-DE.json"
      ]
    },
    {
      "iso": "cs-CZ",
      "domain": "https://www.fielmann.cz",
      "code": "cs-cz",
      "files": [
        "langs/processed/cs-CZ.json"
      ]
    },
    {
      "iso": "pl-PL",
      "domain": "https://www.fielmann.pl",
      "code": "pl-pl",
      "files": [
        "langs/processed/pl-PL.json"
      ]
    },
    {
      "iso": "de-CH",
      "domain": "https://www.fielmann.ch",
      "code": "de-ch",
      "files": [
        "langs/processed/de-CH.json"
      ]
    },
    {
      "iso": "fr-CH",
      "domain": "https://fr.fielmann.ch",
      "code": "fr-ch",
      "files": [
        "langs/processed/fr-CH.json"
      ]
    },
    {
      "iso": "it-CH",
      "domain": "https://it.fielmann.ch",
      "code": "it-ch",
      "files": [
        "langs/processed/it-CH.json"
      ]
    }
  ],
  "defaultLocale": "de-at",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": true,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "langs/processed/",
  "detectBrowserLanguage": false,
  "differentDomains": true,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "plp/lenses-care-plp": false,
    "plp/lenses-plp": false,
    "plp/default-plp": false,
    "plp/content-pages": false,
    "ropo-basket": {
      "de-at": "/anprobeliste/",
      "de-de": "/anprobeliste/",
      "cs-cz": false,
      "pl-pl": false,
      "de-ch": false,
      "fr-ch": false,
      "it-ch": false
    },
    "ropo-plp": {
      "de-at": "/anprobevorort/",
      "de-de": "/anprobevorort/",
      "cs-cz": false,
      "pl-pl": false,
      "de-ch": false,
      "fr-ch": false,
      "it-ch": false
    },
    "niederlassungen/index": {
      "de-at": "/niederlassungen/",
      "de-de": "/niederlassungen/",
      "cs-cz": "/pobocky/",
      "pl-pl": "/salony/",
      "fr-ch": "/succursales/",
      "it-ch": "/filiali/"
    },
    "niederlassungen/[city]/[id]": {
      "de-at": "/niederlassungen/[city]/[id]/",
      "de-de": "/niederlassungen/[city]/[id]/",
      "cs-cz": "/pobocky/[city]/[id]/",
      "pl-pl": "/salony/[city]/[id]/",
      "fr-ch": "/succursales/[city]/[id]/",
      "it-ch": "/filiali/[city]/[id]/"
    },
    "niederlassungen/stadt/[county]/[city]": {
      "de-at": "/niederlassungen/stadt/[county]/[city]/",
      "de-de": "/niederlassungen/stadt/[county]/[city]/",
      "cs-cz": "/pobocky/mesto/[county]/[city]/",
      "pl-pl": "/salony/miasto/[county]/[city]/",
      "fr-ch": "/succursales/ville/[county]/[city]/",
      "it-ch": "/filiali/citta/[county]/[city]/"
    },
    "service/auftragsstatus": {
      "cs-cz": false,
      "pl-pl": "/uslugi/status/",
      "fr-ch": "/service/statut/",
      "it-ch": "/servizi/stato/"
    },
    "service/gebrauchsanweisungen": {
      "cs-cz": "/servis/navod-k-pouziti/",
      "pl-pl": "/uslugi/instrukcje-uzytkowania/",
      "fr-ch": "/service/mode-demploi/",
      "it-ch": "/servizi/istruzioni-per-l-uso/"
    },
    "service/konformitaetserklaerungen": {
      "cs-cz": "/servis/eu-prohlaseni-o-shode/",
      "pl-pl": "/uslugi/deklaracja-zgodnosci/",
      "fr-ch": "/service/certificat-de-conformite/",
      "it-ch": "/servizi/dichiarazione-di-conformita/"
    },
    "subscription/subscription-cancellations": {
      "default": false,
      "de-de": "/spar-abo/kuendigung/",
      "de-at": "/spar-abo/kuendigung/",
      "de-ch": "/spar-abo/kuendigung/",
      "fr-ch": "/abonnement-eco/resiliation/",
      "it-ch": "/abbonamento-risparmio/disdetta/",
      "pl-pl": "/abonament-oszczednosciowy/rezzygnacja/",
      "cs-cz": "/vyhodne-predplatne/zruseni/"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "iso": "de-AT",
    "domain": "https://www.fielmann.at",
    "code": "de-at",
    "files": [
      {
        "path": "langs/processed/de-AT.json"
      }
    ]
  },
  {
    "iso": "de-DE",
    "domain": "https://www.fielmann.de",
    "code": "de-de",
    "files": [
      {
        "path": "langs/processed/de-DE.json"
      }
    ]
  },
  {
    "iso": "cs-CZ",
    "domain": "https://www.fielmann.cz",
    "code": "cs-cz",
    "files": [
      {
        "path": "langs/processed/cs-CZ.json"
      }
    ]
  },
  {
    "iso": "pl-PL",
    "domain": "https://www.fielmann.pl",
    "code": "pl-pl",
    "files": [
      {
        "path": "langs/processed/pl-PL.json"
      }
    ]
  },
  {
    "iso": "de-CH",
    "domain": "https://www.fielmann.ch",
    "code": "de-ch",
    "files": [
      {
        "path": "langs/processed/de-CH.json"
      }
    ]
  },
  {
    "iso": "fr-CH",
    "domain": "https://fr.fielmann.ch",
    "code": "fr-ch",
    "files": [
      {
        "path": "langs/processed/fr-CH.json"
      }
    ]
  },
  {
    "iso": "it-CH",
    "domain": "https://it.fielmann.ch",
    "code": "it-ch",
    "files": [
      {
        "path": "langs/processed/it-CH.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
