<template>
  <div
    class="relative overflow-hidden rounded-md px-4 py-6 leading-tight md:px-8"
    :class="customStyle"
    :data-testid="`inline-message-${type}`"
  >
    <div
      v-if="isInfoSecondary"
      class="absolute inset-y-0 left-0 h-full w-1 bg-[#0140E3]"
    ></div>
    <div class="flex items-center gap-4">
      <LazyIconFielmannInfoCircle
        v-if="isInfo || isInfoSecondary"
        class="size-6"
        :class="{ 'text-[#0140E3]': isInfoSecondary }"
      />
      <LazyIconFielmannCheck
        v-else-if="type === 'success'"
        class="size-6 text-green-700"
      />
      <LazyIconFielmannWarningHexagon
        v-else-if="type === 'warning' || type === 'alert'"
        class="size-6"
      />
      <div class="flex-1">
        <Headline v-if="headline" tag="h4">
          {{ headline }}
        </Headline>
        <div v-dompurify-html="description" class="text-base"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
  headline: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  description: {
    type: String as PropType<string>,
    required: true,
  },
  type: {
    type: String as PropType<
      'success' | 'info' | 'info-secondary' | 'warning' | 'alert'
    >,
    default: 'info',
    validator: (val: string) => {
      return ['success', 'info', 'info-secondary', 'warning', 'alert'].includes(
        val,
      )
    },
  },
})

const isSuccess = computed(() => props.type === 'success')
const isInfo = computed(() => props.type === 'info')
const isInfoSecondary = computed(() => props.type === 'info-secondary')
const isWarning = computed(() => props.type === 'warning')
const isAlert = computed(() => props.type === 'alert')

const customStyle = computed(() => ({
  'bg-secondary': isInfo.value,
  'bg-message-info-secondary': isInfoSecondary.value,
  'bg-message-success': isSuccess.value,
  'bg-message-warning': isWarning.value,
  'bg-message-alert text-white': isAlert.value,
}))
</script>
