<template>
  <form>
    <div class="h-14">
      <SalutationSelect v-model="editableUser.gender" />
    </div>

    <ValidatedInputGroup
      v-slot="{ isValid }"
      :errors="v.email.$errors"
      class="mb-2"
    >
      <FimInput
        id="email"
        v-model:value="editableUser.email"
        autocomplete="username"
        :placeholder="$t('form_fields.email')"
        type="email"
        :is-valid="!isValid"
        required
        :disabled="isSubmitting"
        @update:value="v.email.$touch()"
      />
    </ValidatedInputGroup>

    <ValidatedInputGroup
      v-slot="{ isValid }"
      :errors="v.first_name.$errors"
      class="mb-2"
    >
      <FimInput
        id="first_name"
        v-model:value="editableUser.first_name"
        autocomplete="given-name"
        :placeholder="$t('form_fields.first_name')"
        :is-valid="!isValid"
        required
        :disabled="isSubmitting"
        @update:value="v.email.$touch()"
      />
    </ValidatedInputGroup>

    <ValidatedInputGroup
      v-slot="{ isValid }"
      :errors="v.last_name.$errors"
      class="mb-2"
    >
      <FimInput
        id="last_name"
        v-model:value="editableUser.last_name"
        autocomplete="family-name"
        :placeholder="$t('form_fields.last_name')"
        :is-valid="!isValid"
        required
        :disabled="isSubmitting"
        @update:value="v.email.$touch()"
      />
    </ValidatedInputGroup>

    <FimButton
      type="primary"
      is-full-width
      :disabled="isSubmitting || v.$errors.length > 0"
      :loading="isSubmitting"
      @click="onSubmit()"
    >
      {{ $t('login_page.guest_login.submit') }}
    </FimButton>
  </form>
</template>

<script setup lang="ts">
import type { Gender } from '@scayle/storefront-nuxt'
import useVuelidate from '@vuelidate/core'

const { guestLogin, isSubmitting } = await useAuthentication('guest_login')
const { $validation } = useNuxtApp()

const editableUser = reactive({
  gender: 'f' as Gender,
  first_name: '',
  last_name: '',
  email: '',
})

const v = useVuelidate(
  {
    first_name: {
      required: $validation.rule.required,
      maxLength: $validation.rule.maxLength(30),
    },
    last_name: {
      required: $validation.rule.required,
      maxLength: $validation.rule.maxLength(50),
    },
    email: {
      required: $validation.rule.required,
      email: $validation.rule.email,
    },
  },
  editableUser,
)

const onSubmit = async () => {
  const isValid = await v.value.$validate()
  if (isValid) {
    await guestLogin(editableUser)
  }
}
</script>
