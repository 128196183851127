const useRadioButtonClickEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackRadioButtonClick: (
      element: FimRadioButtonTrackingPayload['element'],
    ) => {
      const payload = {
        element:
          'value' in element
            ? {
                id: element?.dataset?.id || element.dataset?.testid || element.id,
                label: element.dataset?.label || element.labels?.[0]?.innerText,
              }
            : element,
      }

      track('FielmannBasic_RadioButtonClick', payload)
    },
  }
}

export default useRadioButtonClickEvents
