<template>
  <div>
    <div v-if="showBasket" class="mt-4 text-xs lg:hidden">
      <div class="flex items-center justify-center">
        <span class="font-semibold">
          {{ $t('login_page.breadcrump.contact') }}
        </span>
        <LazyIconFielmannArrowRight class="mx-3 inline size-4 text-[#afb9c5]" />
        <span class="font-semibold text-[#afb9c5]">
          {{ $t('login_page.breadcrump.delivery') }}
        </span>
        <LazyIconFielmannArrowRight class="mx-3 inline size-4 text-[#afb9c5]" />
        <span class="font-semibold text-[#afb9c5]">
          {{ $t('login_page.breadcrump.payment') }}
        </span>
      </div>
    </div>
    <div
      class="w-full"
      :class="{
        'flex flex-col-reverse lg:flex-row': showBasket,
      }"
    >
      <div class="sticky top-[60px] h-fit grow">
        <div v-if="showBasket" class="px-12 max-lg:hidden">
          <div
            class="mx-auto flex h-10 max-w-[33.75rem] items-center px-2 pt-12 text-xs"
          >
            <span class="font-semibold">
              {{ $t('login_page.breadcrump.contact') }}
            </span>
            <LazyIconFielmannArrowRight
              class="mx-3 inline size-4 text-[#afb9c5]"
            />
            <span class="font-semibold text-[#afb9c5]">
              {{ $t('login_page.breadcrump.delivery') }}
            </span>
            <LazyIconFielmannArrowRight
              class="mx-3 inline size-4 text-[#afb9c5]"
            />
            <span class="font-semibold text-[#afb9c5]">
              {{ $t('login_page.breadcrump.payment') }}
            </span>
          </div>
        </div>
        <SignInForm
          show-guest-login
          :is-basket-displayed="showBasket"
          :class="{ 'grow !pt-7': showBasket }"
        />
      </div>
      <SignInBasket v-if="showBasket" class="grow lg:max-w-[50%]" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineOptions({ name: 'SigninPage' })
definePageMeta({ pageType: 'signin_page' })

const route = useRoute()
const showBasket = computed(() => {
  return route.query.showBasket === 'true'
})
</script>
