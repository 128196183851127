import type { CentAmount, Product, Variant } from '@scayle/storefront-nuxt'

const useBasketEvents = (track: TrackFunction) => ({
  trackBasketItems: (items: FimBasketItem[] = []) => {
    const basketPayload: BasketData = {
      items: items || [],
      total_campaign_reduction_with_tax: 0.0,
      total_sale_reduction_with_tax: 0.0,
      total_with_tax: 0.0,
      total_without_tax: 0.0,
    }

    items.forEach((basketItem) => {
      basketItem.price.total.appliedReductions
        .filter((reduction) => reduction.category === 'sale')
        .forEach(
          (reduction) =>
            (basketPayload.total_sale_reduction_with_tax +=
              reduction.amount.absoluteWithTax),
        )
      basketItem.price.total.appliedReductions
        .filter((reduction) => reduction.category === 'campaign')
        .forEach(
          (reduction) =>
            (basketPayload.total_campaign_reduction_with_tax +=
              reduction.amount.absoluteWithTax),
        )
      basketPayload.total_with_tax += basketItem.price.total.withTax
      basketPayload.total_without_tax += basketItem.price.total.withoutTax
    })

    track('FielmannBasic_EC_CartView', basketPayload)
  },
  trackAddToBasket: (
    product: Product,
    quantity: number,
    variant?: Variant,
    options?: MapToTrackingPayloadOptions,
  ) => {
    track(
      'FielmannBasic_EC_AddToCart',
      {
        product,
        variant,
        quantity,
      },
      options,
    )
  },
  trackAddMultipleToBasket: (items: FimOrderItem[]) => {
    track('FielmannBasic_EC_AddToCart', { items })
  },
  trackRemoveFromBasket: (
    product: Product,
    quantity: number,
    variant: Variant,
  ) => {
    track('FielmannBasic_EC_RemoveFromCart', {
      product,
      variant,
      quantity,
    })
  },
})
export default useBasketEvents
