<template>
  <Teleport :to="to">
    <FadeInTransition v-if="keep">
      <div
        v-show="isOpen"
        class="fixed right-0 top-0 z-[100] flex w-full bg-black/50"
        :class="fullScreen ? 'h-full' : 'min-h-screen'"
      >
        <div
          ref="modalRef"
          class="relative m-auto size-full max-w-full rounded-2xl bg-white p-10"
          :class="[
            modalClass,
            {
              '!h-[95%] !w-[95%]': fullScreen,
              'md:w-[37.5rem]': !fitWidth,
              'md:w-fit': fitWidth,
            },
          ]"
        >
          <slot name="headline" />
          <button
            v-if="!hideCloseButton"
            data-testid="close-button"
            class="absolute right-6 top-6 z-50 cursor-pointer p-3"
            :aria-label="$t('modal.close')"
            @click="close"
          >
            <LazyIconFielmannClose class="size-6" />
          </button>
          <slot />
        </div>
      </div>
    </FadeInTransition>
    <FadeInTransition v-else>
      <div
        v-if="isOpen"
        class="fixed right-0 top-0 z-[100] flex w-full bg-black/50"
        :class="fullScreen ? 'h-full' : 'min-h-screen'"
      >
        <div
          ref="modalRef"
          class="relative m-auto size-full max-w-full rounded-2xl bg-white p-10"
          :class="[
            modalClass,
            {
              '!h-[95%] !w-[95%]': fullScreen,
              'md:w-[37.5rem]': !fitWidth,
              'md:w-fit': fitWidth,
            },
          ]"
        >
          <slot name="headline" />
          <button
            v-if="!hideCloseButton"
            data-testid="close-button"
            class="absolute right-6 top-6 z-50 cursor-pointer p-3"
            :aria-label="$t('modal.close')"
            @click="close"
          >
            <LazyIconFielmannClose class="size-6" />
          </button>
          <slot />
        </div>
      </div>
    </FadeInTransition>
  </Teleport>
</template>

<script setup lang="ts">
type Props = {
  to?: string
  hideCloseButton?: boolean
  fullScreen?: boolean
  fitWidth?: boolean
  modalClass?: string
  closeOnOutside?: boolean
  identifier?: string
  keep?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  to: 'body',
  hideCloseButton: false,
  fullScreen: false,
  closeOnOutside: true,
  modalClass: undefined,
  identifier: undefined,
})

const emit = defineEmits(['close'])

const modalRef = ref()

const { isOpen, toggle } = useModal(props.identifier || 'modal-state')

const close = () => {
  emit('close')
  toggle(false)
}

onClickOutside(modalRef, () => props.closeOnOutside && close())

const handleKeyUpEvent = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    close()
  }
}

let cleanup: ReturnType<typeof useEventListener>

onMounted(() => {
  // modal state is handled outside
  if (!props.identifier) {
    toggle(true)
  }
  cleanup = useEventListener('keyup', handleKeyUpEvent)
})

onUnmounted(() => {
  // modal state is handled outside
  if (!props.identifier) {
    toggle(false)
  }
  if (cleanup) {
    cleanup()
  }
})

defineOptions({ name: 'AppModal' })
</script>
