<template>
  <div class="mb-4">
    <div class="flex space-x-4">
      <div class="flex size-20 shrink-0 items-center rounded-lg bg-white">
        <ProductImage :image="basketItem.product.images[0]" class="w-[140px]" />
      </div>
      <div class="grow">
        <div class="text-[10px] font-semibold leading-4">
          {{ brand }}
        </div>
        <div class="text-sm font-semibold">
          {{ name }}
        </div>
        <div class="mb-1 text-xs text-[#afb9c5]">
          {{ basketItem.variant.referenceKey }}
        </div>
        <div
          v-for="displayDataItem in displayData"
          :key="displayDataItem.key"
          class="my-0.5 text-xs text-[#36475c]"
        >
          {{ displayDataItem.label }}: {{ displayDataItem.value }}
        </div>
        <div class="text-right text-xs">
          <template v-if="totalReductions.absoluteWithTax">
            <div class="text-[#afb9c5] line-through">
              {{
                toCurrency(
                  totalReductions.absoluteWithTax + actualPrice.withTax,
                )
              }}
            </div>
            <div class="text-[#afb9c5]">
              Sale:
              {{
                withNegativePrefix(toCurrency(totalReductions.absoluteWithTax))
              }}
            </div>
          </template>
          <div
            class="font-semibold"
            :class="{
              'text-pricing': totalReductions.absoluteWithTax,
            }"
          >
            {{ toCurrency(actualPrice.withTax) }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="getItemGroupId(basketItem)">
      <SignInGlassesOptions
        :rx-sub-item-info="basketItem.customData.rxSubItemInfo"
        :product-configuration="basketItem.customData.configurationItems || []"
        :total-price="sumPrice"
        class="my-2"
      />
      <div class="flex justify-between text-xs">
        <div>{{ $t('sign_in.basket.card.rxOptionsTotal') }}</div>
        <div class="font-semibold">
          {{ toCurrency(sumPrice) }}
        </div>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import {
  getFirstAttributeValue,
  getTotalAppliedReductions,
  type AppliedReduction,
  type Price,
} from '@scayle/storefront-core'

const props = defineProps({
  basketItem: {
    type: Object as PropType<FimBasketItem>,
    required: true,
  },
})
const name = getFirstAttributeValue(
  props.basketItem.product.attributes,
  'name',
)?.label

const brand = getFirstAttributeValue(
  props.basketItem.product.attributes,
  'brand',
)?.label

const order = ['attribute-1', 'attribute-2', 'attribute-3', 'attribute-4']
const displayData = Object.entries(props.basketItem.displayData)
  .sort((a, b) => {
    return order.indexOf(a[0]) - order.indexOf(b[0])
  })
  .map(([_key, value]) => value)

const totalReductions = computed<AppliedReduction['amount']>(() => {
  if (props?.basketItem.variant.price?.appliedReductions?.length) {
    return getTotalAppliedReductions(
      props?.basketItem.variant.price,
    ) as AppliedReduction['amount']
  } else {
    return {
      relative: 0,
      absoluteWithTax: 0,
    } as AppliedReduction['amount']
  }
})

const actualPrice = computed<Price>(() => {
  return props.basketItem.variant.price
})

const productConfig = computed(() =>
  getProductConfigRxMainItem(
    props.basketItem.product,
    props.basketItem.variant,
  ),
)

const sumPrice = computed(() => {
  return getRxItemPriceValue(
    productConfig.value.value,
    props.basketItem.customData.configurationItems || [],
  )
})

const glassesOptionTotal =
  props.basketItem.customData?.rxSubItemInfo?.reduce(
    (acc, { priceWithTax }) => {
      return acc + priceWithTax
    },
    0,
  ) || 0

const { withNegativePrefix } = await useBasketReductions()
</script>
