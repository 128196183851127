<template>
  <NuxtImg
    v-if="imgSrc"
    provider="local"
    data-testid="carrier-logo"
    class="mr-2 max-h-8 max-w-20"
    :src="imgSrc"
    :alt="altText"
    loading="lazy"
  />
</template>
<script setup lang="ts">
import { CARRIER_LOGO_PATHS } from '~/constants/order'

const props = defineProps({
  carrierKey: {
    type: String as PropType<string>,
    default: '',
  },
  carrierName: {
    type: String as PropType<string>,
    default: '',
  },
})

const imgSrc = computed(() =>
  isDeliveryCarrier(props.carrierKey)
    ? CARRIER_LOGO_PATHS[props.carrierKey]
    : undefined,
)

const altText = computed(() => `${props.carrierName} Logo`)
</script>
