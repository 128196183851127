import type {
  BasketWithOptions,
  ProductWith,
  WishlistWithOptions,
} from '@scayle/storefront-nuxt'
import { BASKET_WITH_PARAMS } from './basket'
import { PRODUCT_WITH_PARAMS } from './product'
import { SEARCH_WITH_PARAMS } from './search'

export const PRODUCT_WITH_DEFAULT = Object.freeze({
  attributes: 'all',
  advancedAttributes: 'all',
  categories: 'all',
  variants: {
    attributes: 'all',
    advancedAttributes: 'all',
    lowestPriorPrice: true,
  },
  images: 'all',
  priceRange: true,
  lowestPriorPrice: true,
  siblings: {
    images: 'all',
    attributes:'all',
    advancedAttributes: 'all',
    priceRange: true,
    variants: {
      attributes: 'all',
      advancedAttributes: 'all',
      lowestPriorPrice: true,
    },
  },
}) as ProductWith

export const BASKET_WITH = BASKET_WITH_PARAMS as BasketWithOptions

export const WISHLIST_WITH = {
  items: {
    variant: {
      attributes: 'all',
      lowestPriorPrice: true,
    },
    product: PRODUCT_WITH_DEFAULT,
    promotion: true,
  },
  applicablePromotions: true,
} as WishlistWithOptions

export const PRODUCT_WITH_CL = Object.freeze({
  attributes: 'all',
  advancedAttributes: 'all',
  categories: 'all',
  images: 'all',
  priceRange: true,
  lowestPriorPrice: true,
}) as ProductWith

export const PRODUCT_WITH_CL_DETAIL = Object.freeze({
  ...PRODUCT_WITH_CL,
  siblings: { attributes: { withKey: ['numberOfLenses'] }, priceRange: true },
}) as ProductWith

export const PRODUCT_WITH_CARE = Object.freeze({
  ...PRODUCT_WITH_DEFAULT,
}) as ProductWith
export const PRODUCT_WITH_RX = Object.freeze({
  ...PRODUCT_WITH_DEFAULT,
}) as ProductWith
export const PRODUCT_WITH_GLASSES = Object.freeze({
  ...PRODUCT_WITH_DEFAULT,
}) as ProductWith
export const PRODUCT_WITH_MERCH = Object.freeze({
  ...PRODUCT_WITH_DEFAULT,
}) as ProductWith

export const SAFE_PRODUCT_WITH = PRODUCT_WITH_CL

export default {
  wishlist: WISHLIST_WITH,
  basket: BASKET_WITH_PARAMS,
  product: PRODUCT_WITH_PARAMS,
  search: SEARCH_WITH_PARAMS,
}
