import type { ShopCountryCustomData } from '@scayle/storefront-api'
import type { RpcStatus } from '~/types/rpc'

type Options = Parameters<typeof useShopConfiguration>[0]

export type CustomData = {
  pdTool: {
    enabled: boolean
  }
  basketLimit: {
    maxValue: number
    maxValueText: string
  }
  freeShipping: {
    shippingCostInformation: string
    shippingCosts: number
    minBasketValue: number
    textMinNotReached: string
    shippingInfoBoxPdp: string
    productIds: number[]
  }
  rxFreeShipping: {
    enabled: boolean
  }
  navigationTree: {
    treeId: number
    useCustomTree: boolean
  }
  zeroCostFrames: {
    storyblokSlug: string
    enabled: boolean
  }
  correctionalGlasses: {
    enabled: boolean
  }
  ropo?: {
    enabled: boolean
    categoryPath: string
    maxNumberOfArticles: number
    supportedStores: string[]
  }
  osp?: {
    sessionTimeoutInMinutes?: number
    redirectPath?: string
  }
  deliveryEstimates?: {
    warehouse: Record<string, string>
    sellableWithoutStockAdditionalTime: string
    sellableWithoutStockTotalTime: string
  }
}

declare module '@scayle/storefront-api' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface ShopCountryCustomData extends CustomData {}
}

type UseCustomDataReturnType = {
  data: Ref<ShopCountryCustomData | undefined>
  fetching: Ref<boolean>
  fetch: () => Promise<void>
  error: Ref<Error | null>
  status: Ref<RpcStatus> // Assuming ShopConfigurationStatus exists
}

export const useCustomData = ({
  options,
  key = 'useCustomData',
}: Options = {}) => {
  return useShopConfiguration({
    options: {
      ...options,
      // @ts-expect-error
      transform: (data) => {
        return data.customData
      },
    },
    key,
  }) as unknown as UseCustomDataReturnType & Promise<UseCustomDataReturnType>
}
