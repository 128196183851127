<template>
  <select
    class="w-full rounded"
    :value="modelValue"
    @input="triggerInputEvent($event)"
  >
    <option
      v-for="(gender, index) in genders"
      :key="index"
      :value="gender.value"
    >
      {{ gender.label }}
    </option>
  </select>
</template>

<script setup lang="ts">
import type { Gender } from '@scayle/storefront-nuxt'

defineProps({
  modelValue: {
    type: String as PropType<Gender>,
    required: true,
    default: 'f',
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const $i18n = useI18n()

const triggerInputEvent = (event: Event) => {
  emit('update:modelValue', (event?.target as HTMLInputElement)?.value)
}

const genders: { label: string; value: Gender }[] = [
  {
    label: $i18n.t('form_fields.female'),
    value: 'f',
  },
  {
    label: $i18n.t('form_fields.male'),
    value: 'm',
  },
  {
    label: $i18n.t('form_fields.divers'),
    value: 'd',
  },
]
</script>
