import type { Attributes } from '@scayle/storefront-api'
import {
  flattenFieldSet,
  getAttributeValueTuples,
  getFirstAttributeValue,
  getFlattenedAdvancedAttribute,
  type AttributeGroup,
  type FieldSet,
  type Product,
  type ProductWith,
} from '@scayle/storefront-nuxt'
import type { ProductRecommendationAttribute } from '~/constants/product'

export {
  getAttributeValue,
  getAttributeValueTuples,
  getFirstAttributeValue,
} from '@scayle/storefront-nuxt'

const validObject = (entry: any) => 'value' in entry
export const getAdvancedAttributes = ({
  product,
  property,
}: {
  product?: ObjectWith<{ advancedAttributes: Product['advancedAttributes'] }>
  property: string
}) => {
  const valueList: { value: string }[] = getFlattenedAdvancedAttribute(
    product?.advancedAttributes?.[property]?.values || [],
  )
  return product
    ? valueList
        .filter(validObject)
        ?.map(({ value }) => value)
        .join(',')
    : null
}

export const getAdvancedAttribute = ({
  product,
  property,
}: {
  product: any
  property: string
}) => {
  const validObject = (entry: any) => 'value' in entry
  const valueList: { value: string }[] = flattenFieldSet(
    product?.advancedAttributes?.[property]?.values || [],
  ) as unknown as { value: string }[]
  return product ? valueList.find(validObject)?.value : null
}

export const getSiblingsFromAdvancedAttributes = (product: Product) => {
  const siblings = product?.advancedAttributes?.siblings

  if (siblings && siblings.values && siblings.values.length === 1) {
    const value = siblings.values[0]

    if (value && value.fieldSet && value.fieldSet.length === 1) {
      return value.fieldSet[0]
    }
  }

  return []
}

export const getLensQualityUsp = (
  lensQuality: any,
  attribute: 'rxBaseUsp' | 'rxOnTopUsp',
) => {
  return (
    (lensQuality?.advancedAttributes?.[attribute]?.values as Array<{
      fieldSet: FieldSet
    }>) || []
  )
    .map(({ fieldSet }) => fieldSet)
    .filter((sets) => sets.length)
    .map((item) => item[0])
    .map<{
      icon: string
      label: string
    }>(([icon, text]) => ({
      icon: `${icon.value}`,
      label: `${text.value}`,
    }))
}

/**
 * Useful to make vue templates more readable since many components are expecting a string.
 * This helps avoid repetitive type checks and castings.
 */
export const getFirstAttributeLabel = (
  attributes: Attributes | undefined,
  attribute: string,
): string => {
  const value = getFirstAttributeValue(attributes, attribute)
  return value ? value.label : ''
}

export const getAttributesCategory = (
  product: Product | null | undefined,
): AttributeGroup | undefined => product?.attributes?.category

export const handleAttributes = (
  attributes: ProductWith['attributes'],
  recommendationAttributes: ProductRecommendationAttribute[],
): ProductWith['attributes'] => {
  if (
    attributes &&
    attributes !== 'all' &&
    recommendationAttributes.length &&
    attributes.withKey?.length
  ) {
    return {
      ...attributes,
      withKey: [...(attributes.withKey ?? []), ...recommendationAttributes],
    }
  }
  return attributes
}

export const getSortedAttributeValueTuples = (
  attributes: Parameters<typeof getAttributeValueTuples>[0],
  attributeName: Parameters<typeof getAttributeValueTuples>[1],
  locale?: Intl.LocalesArgument,
  options?: Intl.CollatorOptions,
) =>
  getAttributeValueTuples(attributes, attributeName).sort(
    (a, b) => a?.label?.localeCompare(b?.label ?? '', locale, options) ?? 0,
  )
