const useAppointmentEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
) => ({
  trackAppointmentCancellation: ({
    id,
    dateTime,
    service,
    branch,
  }: Appointment) => {
    track('FielmannBasic_AppointmentCancellation', {
      appointments: {
        appointmentId: id,
        appointmentDateTime: dateTime,
        branchId: branch.id,
        productCategoryName: service.productCategory,
        service: service.serviceName,
      },
    })
  },
})

export default useAppointmentEvents
