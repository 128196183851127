export default defineNuxtRouteMiddleware(async (to, from) => {
  const currentShop = useCurrentShop()

  if (to.path.includes('/api') || !currentShop.value) {
    return
  }

  const nuxt = useNuxtApp()
  const getLocalePath = useFimLocalePath()
  const asyncUserData = useRpc('getUser', 'authGuard-user')

  if (!nuxt.ssrContext && !asyncUserData?.data?.value?.user) {
    await asyncUserData.execute()
  }

  const user = nuxt?.ssrContext
    ? nuxt?.ssrContext?.event?.context.$rpcContext.user
    : asyncUserData?.data?.value?.user

  const localePath = (routePath: LinkList[keyof LinkList]['path']) => {
    return getLocalePath(routePath) || routePath
  }

  const isProtectedRoute = (exclude?: string) => {
    const routes = getProtectedRouteList(exclude)

    return routes.find(
      (protectedRoute) => localePath(protectedRoute) === to.path,
    )
  }

  const isGuest = !!user?.status?.isGuestCustomer

  const isProtectRouteForGuest = isGuest && isProtectedRoute('checkout')

  if (!user && isProtectedRoute()) {
    return navigateTo({
      path: localePath(routeList.signin.path),
      query: {
        redirectUrl: to.fullPath,
        showBasket: `${to.fullPath === routeList.checkout.path}`,
      },
    })
  }
  if (user && isProtectRouteForGuest) {
    const redirectPath =
      getQueryParam(to.query, 'redirectUrl') || localePath(routeList.home.path)
    return navigateTo({ path: redirectPath })
  }

  if (user && !isGuest && localePath(routeList.signin.path) === to.fullPath) {
    return navigateTo({ path: routeList.account.path })
  }
})
