<template>
  <div
    class="initial relative overflow-hidden rounded-lg border border-neutral-400 p-2 text-xs"
    :class="{ 'space-y-2': isOpen }"
  >
    <div
      class="flex cursor-pointer justify-between p-1"
      @click="isOpen = !isOpen"
    >
      <div>
        {{ $t('sign_in.basket.card.rxOptions') }}
        <LazyIconFielmannArrowDown
          class="inline size-3 shrink-0 transition"
          :class="{ '-rotate-180': isOpen }"
        />
      </div>
      <div>{{ toCurrency(totalPrice) }}</div>
    </div>
    <div
      ref="content"
      class="relative transition-height"
      :style="isOpen ? { height: contentHeight } : { height: 0 }"
    >
      <div>
        <div
          v-for="item in productConfiguration"
          :key="item.name"
          class="flex justify-between px-1 py-2"
        >
          <div>{{ item.name }}</div>
          <div
            v-if="
              item.price && (item?.price?.appliedReductions || []).length > 0
            "
          >
            <span class="mr-1 text-pricing">{{
              toCurrency(item.price.withTax)
            }}</span>
            <span class="line-through">{{
              typeof item.value === 'number'
                ? toCurrency(item.value)
                : item.value
            }}</span>
          </div>
          <div v-else>
            {{ toCurrency(item?.price?.withTax ?? 0) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core'
import type { ProductConfigurationItem as IProductConfigurationItem } from '../ui/productConfiguration/ProductConfigurationItem'

defineProps({
  rxSubItemInfo: {
    type: Array as PropType<Array<RxSubItemInfo>>,
    default: () => [],
  },
  totalPrice: {
    type: Number,
    default: 0,
  },
  productConfiguration: {
    type: Array as PropType<IProductConfigurationItem[]>,
    required: true,
  },
})

const content = ref()
const isOpen = ref(false)

const { height } = useElementSize(
  content,
  { width: 0, height: 0 },
  { box: 'border-box' },
)

const contentHeight = computed(() =>
  height.value ? `${height.value}px` : 'fit-content',
)
</script>
