<template>
  <Modal hide-close-button>
    <template #headline>
      <div>
        <Headline size="xl" tag="h2" class="mb-4">
          {{ $t('login_page.reset_password.title') }}
        </Headline>
      </div>
    </template>

    <form>
      <ValidatedInputGroup
        v-slot="{ isValid }"
        class="mb-4"
        :errors="v.password.$errors"
      >
        <FimInput
          id="password"
          v-model:value="model.password"
          autocomplete="current-password"
          :placeholder="$t('form_fields.new_password')"
          type="password"
          :is_valid="!isValid"
          required
          :disabled="isSubmitting"
          @update:value="v.password.$touch()"
        />
      </ValidatedInputGroup>

      <div class="flex flex-row-reverse justify-start">
        <FimButton
          :disabled="isSubmitting"
          :loading="isSubmitting"
          @click="onSubmit"
        >
          {{ $t('login_page.reset_password.submit') }}
        </FimButton>
        <FimButton
          :disabled="isSubmitting"
          :loading="isSubmitting"
          type="tertiary"
          size="sm"
          class="mr-4"
          @click="$emit('close:modal')"
        >
          {{ $t('login_page.reset_password.cancel') }}
        </FimButton>
      </div>
    </form>
  </Modal>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'

const emit = defineEmits(['close:modal'])

const route = useRoute()
const { $validation } = useNuxtApp()
const { resetPasswordByHash, isSubmitting } =
  await useAuthentication('reset_password')

const model = reactive({
  password: '',
})

const v = useVuelidate(
  {
    password: {
      required: $validation.rule.required,
      password: $validation.rule.password,
    },
  },
  model,
)

const hash = computed(() => {
  const value = route.query.hash
  const hash = Array.isArray(value) ? value[0] : value
  return hash ?? ''
})

const onSubmit = async () => {
  const isValid = await v.value.$validate()
  if (!isValid) {
    return
  }
  await resetPasswordByHash({ password: model.password, hash: hash.value })
  emit('close:modal')
}
</script>
