<template>
  <div
    :id="`fim-${id}`"
    class="relative flex w-full"
    :class="[
      customClasses,
      {
        'flex-col': labelPosition === 'top',
        'items-center': labelPosition === 'left',
      },
    ]"
  >
    <label :for="id" class="font-medium" :class="labelClass">
      {{ label }}
    </label>

    <div
      class="input-wrapper relative w-full text-lg"
      :class="{ disabled }"
      :style="`--unit-content: ${unitValue}`"
    >
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  id: {
    type: String as PropType<string>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: true,
  },
  labelClass: {
    type: String as PropType<string>,
    default: '',
  },
  labelPosition: {
    type: String as PropType<'left' | 'top'>,
    default: 'top',
  },
  unit: {
    type: String as PropType<string>,
    default: '',
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  customClasses: {
    type: String as PropType<string>,
    default: undefined,
  },
})

const unitValue = computed(() => (props.unit ? `'${props.unit}'` : 'none'))
</script>
<style lang="scss" scoped>
.input-wrapper {
  &::before {
    content: var(--unit-content);
    color: black;
    display: inline-block;
    font-size: inherit;
    position: absolute;
    top: 20%;
    pointer-events: none;
    right: calc(13px + 22px + 13px); // visually 1rem left from the caret icon
  }

  &.disabled::before {
    color: #939599;
  }
}
</style>
