export const debug = async (...args: unknown[]) => {
  // eslint-disable-next-line nuxt/prefer-import-meta
  if (!process.server && process?.env?.NODE_ENV !== 'test') {
    return console.log('DEBUG:', ...args)
  }

  const { inspect } = await import('node:util')
  const { default: supportsColor } = await import('supports-color')

  const _args = args.map((arg) => {
    if (['string', 'boolean', 'number'].includes(typeof arg)) {
      return arg
    }

    return inspect(arg, {
      // Colorize the output
      colors: !!supportsColor.stdout,
      // How wide the output should be in chars
      breakLength: 80,
      // How many properties deep should be visualised
      depth: Infinity,
      // How many array members should be shown
      maxArrayLength: Infinity,
      // How long strings should be
      maxStringLength: Infinity,
      // Display numbers as 100_000, not 100000
      numericSeparator: true,
      // Sort object properties
    })
  })

  console.log('DEBUG:', ..._args)
}
