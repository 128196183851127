<template>
  <InputWrapper
    :id="id"
    :label="label"
    :disabled="disabled"
    :label-position="labelPosition"
    :label-class="labelClass"
    :unit="unit"
  >
    <input
      :id="id"
      v-model="inputValue"
      :type="type"
      v-bind="$attrs"
      class="w-full rounded border-neutral-40 text-lg focus:border-[#24272A] focus:outline-none focus:ring-0"
      :class="{
        'border-neutral-40 text-neutral-40': inputValue === '' || disabled,
        'border-[#FDA29B]': !isValid,
        'border-neutral-40': isValid,
      }"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="onChange"
    />
  </InputWrapper>
</template>
<script setup lang="ts">
const props = defineProps({
  id: {
    type: String as PropType<string>,
    required: true,
  },
  value: {
    type: [Number, String] as PropType<number | string>,
    required: true,
  },
  type: {
    type: String as PropType<string>,
    default: 'text',
  },
  label: {
    type: String as PropType<string>,
    default: '',
  },
  labelClass: {
    type: String as PropType<string>,
    default: '',
  },
  labelPosition: {
    type: String as PropType<'left' | 'top'>,
    default: 'top',
  },
  placeholder: {
    type: String as PropType<string>,
    default: '',
  },
  unit: {
    type: String as PropType<string>,
    default: '',
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isValid: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  debounce: {
    type: Number as PropType<number>,
    default: 50,
  },
})

const emit = defineEmits<{
  (e: 'update:value', value: string | number): void
}>()

const { value } = toRefs(props)
const inputValue = ref(props.value)

const scope = effectScope()
onScopeDispose(() => scope.stop())

scope.run(() => {
  if (import.meta.server) {
    return
  }
  watch(value, (newValue) => {
    inputValue.value = newValue
  })
})

const onChange = useDebounce({ delay: props.debounce }, () =>
  emit('update:value', inputValue.value),
)
</script>
