<template>
  <div class="flex justify-end justify-self-stretch">
    <RopoBasketHeaderIcon
      v-if="isRopoEnabled"
      data-testid="ropo-basket-main-menu-link"
    ></RopoBasketHeaderIcon>
    <FimLink
      v-else
      :to="localePath({ name: routeList.stores.name })"
      size="sm"
      :aria-label="$t('footer.store_finder.heading')"
      class="px-2"
      data-prefetch
      data-testid="store-finder-main-menu-link"
      data-tracking-id="navigation_appointment"
      data-tracking-label="Appointment"
      @click="onLinkClick"
    >
      <LazyIconFielmannCalendar class="size-7 stroke-[1.5]" />
    </FimLink>

    <FimLink
      v-if="isLoggedInUser"
      key="logged-in"
      class="header-main-menu__user no-prefetch w-10 px-2"
      :aria-label="$t('myaccount.menu_title')"
      :to="localePath(routeList.account.name)"
      size="sm"
      data-testid="header-account"
      data-tracking-id="navigation_account"
      data-tracking-label="Account"
      @click="onLinkClick"
    >
      <LazyIconFielmannUserVerified class="mb-1 size-7 stroke-[1.5]" />
    </FimLink>
    <FimLink
      v-if="!isLoggedInUser"
      key="logged-out"
      class="header-main-menu__user no-prefetch w-10 px-2"
      :aria-label="$t('global.sign_in')"
      :to="localePath({ name: routeList.signin.name })"
      size="sm"
      data-testid="header-account"
      data-tracking-id="navigation_account"
      data-tracking-label="Account"
      @click="onLinkClick"
    >
      <LazyIconFielmannUser class="size-7 stroke-[1.5]" />
    </FimLink>

    <WishlistLink />
    <HeaderBasketPopover :bold-on-active="false" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useFeatureToggles } from '~/composables/useFeatureToggles'

const localePath = useFimLocalePath()
const {
  isLoggedIn,
  user,
  fetch: fetchUser,
} = await useUser({
  immediate: false,
  lazy: true,
})
const { isRopoEnabled } = await useFeatureToggles()
const { trackMenuIconClick } = await useTrackingEvents()

const onLinkClick = (event: MouseEvent) => {
  trackMenuIconClick({ element: event.target as HTMLAnchorElement })
}

const isLoggedInUser = computed(
  () => isLoggedIn.value && !user.value?.status?.isGuestCustomer,
)

onMounted(() => {
  fetchUser()
})
</script>

<style scoped>
@media (width <375px) {
  /* stylelint-disable-next-line selector-class-pattern */
  .header-main-menu__user {
    display: none;
  }
}
</style>
